<template>
    <div class="seongdong_curation_wrap all" :class="getCurationCss">
        <h1 class="curation_title">큐레이션 설정</h1>
        <div class="curation_body">
            <ul class="curation_body_list">
                <li class="curation_body_list_item">
                    <h2 class="curation_body_list_title">연령대</h2>
                    <ul class="curation_list">
                        <li class="curation_list_item" v-for="item in getAgeList" :key="item.id">
                            <label :for="item.id" class="curation_item" :class="age === item.value ? 'active' : ''">{{ item.text
                            }}</label>
                            <input :id="item.id" type="radio" hidden :value="item.value" v-model="age">
                        </li>
                    </ul>
                </li>
                <li class="curation_body_list_item">
                    <h2 class="curation_body_list_title">국내곡 비율 <span class="sub_title" v-if="alertCountry">국내곡 비율은 최대 80%까지 선택
                        가능합니다.</span></h2>
                    <div class="percent_bar">
                        <div class="percent_item half first" :class="{ checked: parseInt(curationSetting.domesticRate) > 0 }"
                             @click.prevent="clickCountry('0')">
                            <input type="checkbox" id="zero" hidden value="0">
                        </div>
                        <div class="percent_item" :class="{ checked: parseInt(curationSetting.domesticRate) > 10 }"
                             @click.prevent="clickCountry('10')">
                            <div v-show="parseInt(curationSetting.domesticRate) == 10" class="last_checked"></div>
                            <label for="ten" class="percent-dot"></label>
                            <input type="checkbox" id="ten" hidden value="10">
                        </div>
                        <div class="percent_item" :class="{ checked: parseInt(curationSetting.domesticRate) > 20 }"
                             @click.prevent="clickCountry('20')">
                            <div v-show="parseInt(curationSetting.domesticRate) === 20" class="last_checked"></div>
                            <label for="twenty" class="percent-dot"></label>
                            <input type="checkbox" id="twenty" hidden value="20">
                        </div>
                        <div class="percent_item" :class="{ checked: parseInt(curationSetting.domesticRate) > 30 }"
                             @click.prevent="clickCountry('30')">
                            <div v-show="parseInt(curationSetting.domesticRate) === 30" class="last_checked"></div>
                            <label for="ten" class="percent-dot"></label>
                            <input type="checkbox" id="ten" hidden value="30">
                        </div>
                        <div class="percent_item" :class="{ checked: parseInt(curationSetting.domesticRate) > 40 }"
                             @click.prevent="clickCountry('40')">
                            <div v-show="parseInt(curationSetting.domesticRate) === 40" class="last_checked"></div>
                            <label for="ten" class="percent-dot"></label>
                            <input type="checkbox" id="ten" hidden value="40">
                        </div>
                        <div class="percent_item" :class="{ checked: parseInt(curationSetting.domesticRate) > 50 }"
                             @click.prevent="clickCountry('50')">
                            <div v-show="parseInt(curationSetting.domesticRate) === 50" class="last_checked"></div>
                            <label for="ten" class="percent-dot"></label>
                            <input type="checkbox" id="ten" hidden value="50">
                        </div>
                        <div class="percent_item" :class="{ checked: parseInt(curationSetting.domesticRate) > 60 }"
                             @click.prevent="clickCountry('60')">
                            <div v-show="parseInt(curationSetting.domesticRate) === 60" class="last_checked"></div>
                            <label for="ten" class="percent-dot"></label>
                            <input type="checkbox" id="ten" hidden value="60">
                        </div>
                        <div class="percent_item" :class="{ checked: parseInt(curationSetting.domesticRate) > 70 }"
                             @click.prevent="clickCountry('70')">
                            <div v-show="parseInt(curationSetting.domesticRate) === 70" class="last_checked"></div>
                            <label for="ten" class="percent-dot"></label>
                            <input type="checkbox" id="ten" hidden value="70">
                        </div>
                        <div class="percent_item" :class="{ checked: parseInt(curationSetting.domesticRate) > 80 }"
                             @click.prevent="clickCountry('80')">
                            <div v-show="parseInt(curationSetting.domesticRate) === 80" class="last_checked"></div>
                            <label for="ten" class="percent-dot"></label>
                            <input type="checkbox" id="ten" hidden value="80">
                        </div>
                        <div class="percent_item" :class="{ checked: parseInt(curationSetting.domesticRate) > 90 }"
                             @click.prevent="clickCountry('90')">
                            <label for="ninety" class="percent-dot"></label>
                            <input type="checkbox" id="ninety" hidden value="90">
                        </div>
                        <div class="percent_item half last">
                            <input type="checkbox" id="onehundred" hidden value="100">
                        </div>
                    </div>
                    <div class="curation_percent">
                        <span class="percent_text">국내 {{ curationSetting.domesticRate }}%</span>
                        <span class="percent_text">해외 {{ 100 - parseInt(curationSetting.domesticRate) }}%</span>
                    </div>
                </li>
                <li class="curation_body_list_item">
                    <h2 class="curation_body_list_title">선호 장르 <span v-if="alertCountryTrot" class="sub_title">해외곡만 선호하시는 경우, 트로트는
                        선택 불가합니다.</span> </h2>
                    <ul class="curation_list mt0">
                        <ul class="curation_list">
                            <li class="curation_list_item" v-for="(item, index) in getGenreList.slice(0, 6)" :key="item.id"
                                :class="{ 'mr0': index === 5 }">
                                <label :for="item.id" class="curation_item" :class="{ 'active': genre.includes(item.value) }">{{
                                    item.koText
                                }}</label>
                                <input :id="item.id" type="checkbox" hidden :value="item.value" v-model="genre">
                            </li>
                        </ul>
                        <ul class="curation_list">
                            <li class="curation_list_item" v-for="item in getGenreList.slice(6, 11)" :key="item.id">
                                <label :for="item.id" class="curation_item"
                                       :class="{ 'active': genre.includes(item.value), 'disabled': item.value === 10 && parseInt(curationSetting.domesticRate) === 0 }">{{
                                           item.koText
                                       }}</label>
                                <input :id="item.id" type="checkbox" hidden :value="item.value" v-model="genre">
                            </li>
                        </ul>
                    </ul>
                </li>
                <li class="curation_body_list_item">
                    <h2 class="curation_body_list_title">선호 시대</h2>
                    <ul class="curation_list">
                        <li class="curation_list_item" v-for="item in getPeroid" :key="item.id">
                            <label :for="item.id" class="curation_item" :class="peroid.includes(item.value) ? 'active' : ''">{{
                                item.koText
                            }}s</label>
                            <input :id="item.id" type="checkbox" hidden :value="item.value" v-model="peroid">
                        </li>

                    </ul>
                </li>
                <li class="curation_body_list_item">
                    <h2 class="curation_body_list_title">선호음악 분위기(3개 이상 선택)</h2>
                    <ul class="curation_list">
                        <li class="curation_list_item" v-for="(item, index) in getEmotion.slice(0, 7)" :key="item.id"
                            :class="{ 'mr0': index === 6 }">
                            <label :for="item.id" class="curation_item" :class="emotion.includes(item.value) ? 'active' : ''">{{
                                item.koText
                            }}</label>
                            <input :id="item.id" type="checkbox" hidden :value="item.value" v-model="emotion">
                        </li>
                    </ul>
                    <ul class="curation_list">
                        <li class="curation_list_item" v-for="item in getEmotion.slice(7, 9)" :key="item.id">
                            <label :for="item.id" class="curation_item" :class="emotion.includes(item.value) ? 'active' : ''">{{
                                item.koText
                            }}</label>
                            <input :id="item.id" type="checkbox" hidden :value="item.value" v-model="emotion">
                        </li>
                    </ul>
                </li>
            </ul>
        </div>
        <div class="curation_bottom">
            <button class="curation_btn" @click="closeCurationModal">취소</button>
            <button class="curation_btn confirm" :disabled="btnDisabled" @click="checkCondition">적용</button>
        </div>
    </div>
</template>
<script>
import axios from "axios";
import { cloneDeep } from 'lodash';
import { updateCurationInfoExtra } from "@/service/api/profileApi";
export default {
  name: 'SeongDongCuration',
  emits: [
    'close-curationall',
    'confirm-curation',
    'peroid-alert-modalrap',
    'period-alert-modal-elec',
    'period-alert-modal-new',
    'period-alert-modal-rap-elec',
    'period-alert-modal-rnb',
    'period-alert-modal-elec2',
    'period-alert-modal-jazz',
    'period-alert-modal-rapdomestic',
    'period-alertmodal-new1980',
    'period-alert-modal-rnbnew'],
  props: {
    curation: Object,
    sendcuration: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  data () {
    return {
      alertCountryTrot: false,
      alertCountry: false,
      age: '',
      genre: [],
      peroid: [],
      emotion: [],
      ageGroup: '',
      domesticRate: '0',
      curationSetting: {
        mood: null,
        ageGroup: '',
        carMood: null,
        carPurpose: [],
        domesticRate: '0',
        genre: [],
        period: [],
        emotion: [],
        target: [],
        targetUnit: []
      }
    };
  },
  created () {
    this.curationSetting = cloneDeep(this.$store.getters["player/getCurationInfo"]);

    this.age = this.curationSetting.ageGroup;
    this.domesticRate = this.curationSetting.domesticRate;
    this.genre = this.curation.genre.map(item => this.$store.getters["player/getCurationStoreGenre"](item));
    this.peroid = this.curation.period.map(item => this.$store.getters["player/getCurationStorePeroid"](item));

    this.emotion = this.curation.emotion.map(item => this.$store.getters["player/getCurationStoreEmotion"](item));

    if (Object.keys(this.sendcuration).length > 0) {
      const { type, value } = this.sendcuration;
      if (type === 'ageGroup') {
        this.age = value;
      } else if (type === 'domesticRate') {
        this.curationSetting.domesticRate = Number(value);
      } else if (type === 'genre') {
        this.genre = value;
      } else if (type === 'peroid') {
        this.peroid = value;
      } else if (type === 'emotion') {
        this.emotion = value;
      }
    }
  },

  mounted () {

  },

  methods: {
    closeCurationModal () {
      this.$emit('close-curationall');
    },
    clickCountry (value) {
      if (value > 80) {
        this.alertCountryTrot = false;
        this.alertCountry = true;
        return false;
      }
      this.alertCountryTrot = false;
      this.alertCountry = false;
      this.curationSetting.domesticRate = value;

      const idx = this.genre.indexOf(10);
      if (value === '0' && idx > -1) {
        this.curationSetting.genre = this.curationSetting.genre.filter((el) => el !== 10); // 트로트 장르 빼기
        this.alertCountryTrot = true;
      }
    },
    checkCondition () {
      const peroidNo = this.peroid.join(',');
      // 해외 100% 장르 선택장르가 1개인 경우
      if (parseInt(this.curationSetting.domesticRate) === 0 && this.genre.length === 1) {
        if (parseInt(this.genre[0]) === 2 && peroidNo === '1') { // 장르가 랩/힙합이고 시대가 1980s 인 경우
          this.$emit('peroid-alert-modalrap');
        } else if (parseInt(this.genre[0]) === 6) { // 장르가 일렉트로니카인 경우
          // 시대가 1980s, 1990s, 1980s/1990s, 1990s/1980s 인 경우 안내창 오픈
          peroidNo === '1' || peroidNo === '2' || peroidNo === '1,2' || peroidNo === '2,1' ? this.$emit('period-alert-modal-elec') : this.updateCuration();
        } else if (this.genre[0] === 12 && this.genre.length < 3) {
          this.$emit('period-alert-modal-new');
        } else {
          this.updateCuration();
        }
      } else if (parseInt(this.curationSetting.domesticRate) === 0 && this.genre.length === 2) { // 해외 100% 장르 선택장르가 2개인 경우
        const check = this.genre.map(item => parseInt(item)).includes(2 || 6); // 장르 선택값이 랩/힙합, 일렉트로니카 인 경우 true
        check && peroidNo === '1' ? this.$emit('period-alert-modal-rap-elec') : this.updateCuration(); // 시대가 1980s인 경우 안내창 오픈
      } else if (parseInt(this.curationSetting.domesticRate) === 80 && this.genre.length === 1) { // 국내 80% 이고 선택장르가 1개인 경우
        if (parseInt(this.genre[0]) === 4 && peroidNo === '1') { // 장르가 R&B/Soul이고 시대가 1980s 인 경우
          this.$emit('period-alert-modal-rnb');
        } else if (parseInt(this.genre[0]) === 6) { // 장르가 일렉트로니카인 경우
          // 시대가 1980s, 1990s, 1980s/1990s, 1990s/1980s 인 경우 안내창 오픈
          peroidNo === '1' || peroidNo === '2' || peroidNo === '1,2' || peroidNo === '2,1' ? this.$emit('period-alert-modal-elec2') : this.updateCuration();
        } else if (parseInt(this.genre[0]) === 5 && peroidNo === '1') { // 장르가 재즈이고 시대가 1980s 인 경우
          this.$emit('period-alert-modal-jazz');
        } else if (parseInt(this.genre[0]) === 2 && peroidNo === '1') { // 장르가 랩/힙합이고 시대가 1980s 인 경우
          this.$emit('period-alert-modal-rapdomestic');
        } else if (parseInt(this.genre[0]) === 12) { // 선택한 장르가 뉴에이지인 경우
          // 시대가 1980s, 1990s, 1980s/1990s, 1990s/1980s 인 경우 안내창 오픈
          peroidNo === '1' || peroidNo === '2' || peroidNo === '1,2' || peroidNo === '2,1' ? this.$emit('period-alertmodal-new1980') : this.updateCuration();
        } else {
          this.updateCuration();
        }
      } else if (parseInt(this.curationSetting.domesticRate) === 80 && this.genre.length === 2) {
        const checkGenre = this.genre.map(item => parseInt(item)).includes(4 || 12); // 장르 선택값이 R&B/Soul, 뉴에이지 인 경우 true
        checkGenre && peroidNo === '1' ? this.$emit('period-alert-modal-rnbnew') : this.updateCuration(); // 시대가 1980s인 경우 안내창 오픈
      } else if (parseInt(this.curationSetting.domesticRate) !== 0 && this.genre.length === 1) { // 해외100%가 아니고 선택한 장르가 1개인 경우
        if (parseInt(this.genre[0]) === 12) { // 선택한 장르가 뉴에이지인 경우
          // 시대가 1980s, 1990s, 1980s/1990s, 1990s/1980s 인 경우 안내창 오픈
          peroidNo === '1' || peroidNo === '2' || peroidNo === '1,2' || peroidNo === '2,1' ? this.$emit('period-alertmodal-new1980') : this.updateCuration();
        } else if (parseInt(this.genre[0]) === 2 && peroidNo === '1') { // 선택한 장르가 랩/힙합이고 선택한 시대가 1980s인 경우
          this.$emit('period-alert-modal-rapdomestic');
        } else if (parseInt(this.genre[0]) === 6) { // 장르가 일렉트로니카인 경우
          // 시대가 1980s, 1990s, 1980s/1990s, 1990s/1980s 인 경우 안내창 오픈
          peroidNo === '1' || peroidNo === '2' || peroidNo === '1,2' || peroidNo === '2,1' ? this.$emit('period-alert-modal-elec2') : this.updateCuration();
        } else {
          this.updateCuration();
        }
      } else {
        this.updateCuration();
      }
    },
    async updateCuration () {
      const spaceId = this.$cookies.get("spaceId");
      const aToken = this.$cookies.get("aToken");
      const headers = { "X-AUTH-TOKEN": aToken };
      const curationInfo = {
        spaceId,
        domesticRate: this.curationSetting.domesticRate,
        foreignRate: String(100 - parseInt(this.curationSetting.domesticRate)),
        genre: this.genre.sort((a, b) => a - b).join(','),
        period: this.peroid.sort((a, b) => a - b).join(','),
        emotion: this.emotion.sort((a, b) => a - b).join(',')
      };

      try {
        // this.$store.commit("seongdongplayer/setLoadingStatus", true);
        // this.$store.commit("seongdongplayer/setCurUpdate", "updated");
        const { data: curationTempResult } = await this.updateTempCuration(curationInfo, headers);
        const { resultCd: curationTempResultCd } = curationTempResult;
        if (curationTempResultCd === '0000') {
          localStorage.setItem("spaceId", spaceId);
          const { data: updateCurationData } = await this.updateConfirmCuration(spaceId, headers);
          const { resultCd: updateCd } = updateCurationData;

          if (updateCd === "0000") {
            this.closeCurationModal();
            localStorage.setItem("seongdongpath", "SeongdongLibaryView");
            updateCurationInfoExtra(spaceId, this.age);
            this.$router.push({ name: "MySpaceUpdateStore5", params: { status: "updateCuration", path: "SeongdongLibaryView" } }).catch(() => { });
          } else {
            this.failUpdateCuration();
          }
        }
      } catch (error) {
        console.error("updateCuration error : ", error);
        this.failUpdateCuration();
      }
    },
    failUpdateCuration () {
      alert("선호음악 선정에 실패하였습니다.");
    },
    async updateTempCuration (curationInfo, headers) {
      return await axios.post("/api/profile/updateCurationTemp", curationInfo, { headers });
    },
    async updateConfirmCuration (spaceId, headers) {
      return await axios.post("/api/profile/updateCuration", { spaceId }, { headers });
    }
  },
  computed: {
    getCurationCss () {
      return this.$store.getters.gettersCurationCssClass;
    },
    btnDisabled () {
      if (parseInt(this.curationSetting.domesticRate) === 0 && (this.genre.includes(10) || this.genre.includes('10'))) {
        return true;
      } else if (this.age.length === 0 || this.genre.length === 0 || this.peroid.length === 0 || this.emotion.length <= 2) {
        return true;
      }
      return false;
    },
    getAgeList () {
      return this.$store.getters['curation/getAge'];
    },
    getGenreList () {
      return this.$store.getters['curation/getSeondongGenre'];
    },
    getPeroid () {
      return this.$store.getters['curation/getPeroid'];
    },
    getEmotion () {
      return this.$store.getters['curation/getEmotion'];
    }
  }
};
</script>
<style scoped src="@/assets/css/seongdonglibary/seongdongcuration.css"></style>
